<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view v-else-if="!subPermission.show" />

    <div v-else>
      <b-card no-body class="card-developer-meetup">
        <b-card-body>
          <b-row>
            <b-col md="3">
              <b-media no-body class="">
                <b-media-aside>
                  <b-avatar rounded variant="light-primary" size="35">
                    <feather-icon icon="UserIcon" size="18" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h5 class="mb-0 text-primary">{{ $t("g.NameSupplier") }}</h5>
                  <h5 class="mb-0">{{ data_supplier.name }}</h5>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="3">
              <b-media no-body class="">
                <b-media-aside>
                  <b-avatar rounded variant="light-primary" size="35">
                    <feather-icon
                      :icon="
                        data_supplier.is_available
                          ? 'UserCheckIcon'
                          : 'UserXIcon'
                      "
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h5 class="mb-0 text-primary">
                    {{ $t("g.SupplierIsAvailable") }}
                  </h5>
                  <h5 class="mb-0">
                    {{
                      data_supplier.is_available
                        ? $t("g.Is Available")
                        : $t("g.Not Available")
                    }}
                  </h5>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="3">
              <b-media no-body class="">
                <b-media-aside>
                  <b-avatar rounded variant="light-primary" size="35">
                    <feather-icon icon="PhoneIcon" size="18" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h5 class="mb-0 text-primary">{{ $t("g.Phone") }}</h5>
                  <h5 class="mb-0">{{ data_supplier.phone.phone }}</h5>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="3">
              <b-media no-body class="">
                <b-media-aside>
                  <b-avatar rounded variant="light-primary" size="35">
                    <feather-icon icon="PaperclipIcon" size="18" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h5 class="mb-0 text-primary">{{ $t("g.DeviceCount") }}</h5>
                  <h5 class="mb-0">{{ data_devices.length }}</h5>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-row class="justify-content-center">
        <b-col md="12">
          <b-card>
            <!-- search input -->
            <div class="custom-search d-flex justify-content-between mb-1">
              <div class="d-flex align-items-center">
                <div>
                  <label class="mr-1">{{ $t("g.searchLabel") }}</label>
                  <b-form-input
                    v-model="search_device"
                    :placeholder="$t('g.searchHereByBrandOrName')"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </div>
            </div>
            <div class="mt-1">
              <div class="mb-2" v-for="item in data_devices" :key="item.id">
                <div class="mb-2">
                  <h3>{{ item.id }}#</h3>
                </div>
                <!-- *** -->
                <b-row>
                  <b-col>
                    <div class="d-flex row gap-2 justify-content-between">
                      <!-- ** -->
                      <b-col>
                        <div
                          class="d-flex flex-column justify-content-between flex-wrap"
                        >
                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.DeviceName") }} :
                            </h5>
                            <h5>{{ item.name }}</h5>
                          </div>
                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.Brand") }} :
                            </h5>
                            <h5>{{ item.brand }}</h5>
                          </div>
                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.Category") }} :
                            </h5>
                            <h5>{{ item.category.name }}</h5>
                          </div>
                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.Specs") }} :
                            </h5>
                            <h5>
                              {{ item.specs }}
                            </h5>
                          </div>
                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.Reference_number") }} :
                            </h5>
                            <h5>
                              {{ item.reference_number }}
                            </h5>
                          </div>
                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.ArrivalDate") }}:
                            </h5>
                            <h5>
                              {{ formatDate(item.arraival_date) }}
                            </h5>
                          </div>
                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.Identifier") }}:
                            </h5>
                            <h5>
                              {{ item.identifier }}
                            </h5>
                          </div>
                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.Device_attaches") }}:
                            </h5>
                            <h5>
                              {{ item.device_attaches }}
                            </h5>
                          </div>
                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.in_maintenance") }}:
                            </h5>
                            <h5>
                              {{
                                item.in_maintenance ? $t("g.yes") : $t("g.no")
                              }}
                            </h5>
                          </div>
                        </div>
                      </b-col>

                      <!-- ** -->
                      <!-- * -->
                      <b-col>
                        <div
                          class="d-flex flex-column justify-content-between flex-wrap"
                        >
                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.serviceEntry_date") }}:
                            </h5>
                            <h5>
                              {{ formatDate(item.serviceEntry_date) }}
                            </h5>
                          </div>

                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.electricInputType") }}:
                            </h5>
                            <h5 v-if="item.electricInputType">
                              {{ item.electricInputType.name }}
                            </h5>
                          </div>

                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.test_standards") }}:
                            </h5>
                            <h5>
                              {{ item.test_standards }}
                            </h5>
                          </div>
                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.working_area") }}:
                            </h5>
                            <h5>
                              {{ item.working_area }}
                            </h5>
                          </div>

                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.serviceEntry_date") }}:
                            </h5>
                            <h5>
                              {{ formatDate(item.serviceEntry_date) }}
                            </h5>
                          </div>

                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.out_of_service_date") }}:
                            </h5>
                            <h5>
                              {{ formatDate(item.out_of_service_date) }}
                            </h5>
                          </div>

                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.made_in") }}:
                            </h5>
                            <h5>
                              {{
                                item.made_in.name
                                  ? item.made_in.name
                                  : item.made_in.code
                              }}
                            </h5>
                          </div>
                          <div class="d-flex gap-3 space">
                            <h5 class="text-primary space-nowrap">
                              {{ $t("g.maintenanceType") }}:
                            </h5>
                            <h5>
                              {{ item.maintenanceType.name }}
                            </h5>
                          </div>
                        </div>
                      </b-col>

                      <!-- * -->
                    </div>
                  </b-col>
                </b-row>

                <!-- *** -->
                <div class="mt-3 mb-3">
                  <h5 class="text-primary space-nowrap">
                    {{ $t("g.maintainProcess") }} :
                  </h5>
                  <div
                    v-for="process in item.maintainProcess"
                    :key="process.id"
                  >
                    <div class="d-flex align-items-center">
                      <div class="d-flex gap-3 space">
                        <h5 class="text-primary space-nowrap">
                          {{ $t("g.start_date") }}:
                        </h5>
                        <h5>
                          {{ formatDate(process.start_date) }}
                        </h5>
                      </div>
                      <div class="d-flex gap-3 space">
                        <h5 class="text-primary space-nowrap">
                          {{ $t("g.end_date") }}:
                        </h5>
                        <h5>
                          {{ formatDate(process.end_date) }}
                        </h5>
                      </div>
                    </div>

                    <div>
                      <h5 class="text-primary space-nowrap">
                        {{ $t("g.notes") }}:
                      </h5>
                      <p>
                        {{ process.notes }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="line" />
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BCardBody,
  BRow,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BMedia,
  BFormInput,
} from "bootstrap-vue";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import dateTimeInFormat from "@/libs/format-date";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import store from "@/store/index";

export default {
  components: {
    Loading,
    Error,
    BCard,
    BCol,
    BCardBody,
    BRow,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BMedia,
    BFormInput,
    ContentNotView,
  },
  data() {
    return {
      data_supplier: [],
      data_devices: [],
      currentId: null,
      isLoading: true,
      isError: null,
      formatDate: dateTimeInFormat,
      search_device: "",
      subPermission: {},
    };
  },

  watch: {
    search_device(val) {
      let query = val.trim().toLowerCase();
      this.data_devices = this.data_supplier.devices.filter(
        (item) =>
          item.name.trim().toLowerCase().includes(query) ||
          item.brand.trim().toLowerCase().includes(query)
      );
    },
  },

  beforeMount() {
    this.currentId = this.$route.params.id;

    this.$http
      .get(`admin/suppliers/${this.currentId}`)
      .then((response) => {
        if (response.status === 200) {
          this.data_supplier = response.data.data;
          this.data_devices = response.data.data?.devices;
          setTimeout(() => {
            this.isLoading = false;
            this.isError = false;
            store.dispatch("GET_PERMISSION", "suppliers");
            this.subPermission = store.state.permissions.sub;
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.isLoading = false;
        this.isError = true;
      });
  },
};
</script>

<style>
.gap-3 {
  gap: 3px;
}

.gap-2 {
  gap: 2rem;
}
.space-nowrap {
  white-space: nowrap;
}

[dir="rtl"] .space {
  margin-left: 10px;
  margin-top: 10px;
}
[dir="ltr"] .space {
  margin-right: 10px;
  margin-top: 10px;
}

.line {
  margin: 1rem auto;
  background-color: #41414151;
  width: 70%;
  height: 1px;
}
</style>
